import * as React from "react";
import Header, { HeaderTarget } from "./layout/Header";
import "./App.scss";
import BumperPage from "./bumper/BumperPage";
import AboutPage from "./about/AboutPage";
import ArcanePage from "./arcane/ArcanePage";
import ComingSoonHeader from "./coming_soon/ComingSoonHeader";
import Footer from "./layout/Footer";
import NewsPage from "./news/NewsPage";
import AdminPage from "./admin/AdminPage";

const useAdmin = (): boolean => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const keysRef = React.useRef<string[]>([]);
  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      keysRef.current = keysRef.current
        .filter((key) => key !== e.key)
        .concat(e.key);
      const keys = keysRef.current;
      if (keys.includes("Control") || keys.includes("Meta")) {
        if (keys.includes(".") || keys.includes(">")) {
          setIsAdmin(true);
        }
      }
    };
    const onKeyUp = (e: KeyboardEvent) => {
      keysRef.current = keysRef.current.filter((key) => key !== e.key);
    };

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  return isAdmin;
};

const App = () => {
  const isAdmin = useAdmin();

  const aboutRef = React.useRef<HTMLDivElement>(null);
  const arcaneRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);
  const targets = React.useMemo<HeaderTarget[]>(
    () => [
      { name: "the car", ref: aboutRef, size: 3 },
      { name: "about arcane", ref: arcaneRef, size: 6 },
      { name: "contact", ref: footerRef, size: 3 },
    ],
    [],
  );

  if (isAdmin) {
    return (
      <div className="App container-fluid">
        <ComingSoonHeader />
        <div className="page-wrapper">
          <AdminPage />
          <Footer />
        </div>
      </div>
    );
  }
  return (
    <div className="App container-fluid">
      <Header targets={targets} />
      <div className="page-wrapper">
        <BumperPage />
        <AboutPage ref={aboutRef} />
        <ArcanePage ref={arcaneRef} />
        <NewsPage />
        <Footer ref={footerRef} />
      </div>
    </div>
  );
};

export default App;
