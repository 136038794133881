import * as React from "react";
import { FlexAlignItems, FlexJustifyContent } from ".";
import "./flexboxgrid.scss";

type Props = {
  reverse?: boolean;
  alignItems?: FlexAlignItems;
  justifyContent?: FlexJustifyContent;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
};

const Row = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      reverse,
      alignItems,
      justifyContent,
      className,
      style,
      onClick,
      children,
    },
    ref,
  ) => {
    const classes = ["row", reverse ? "reverse" : undefined]
      .concat(className?.split(" "))
      .filter((v) => v !== undefined && v.length > 0);
    return (
      <div
        ref={ref}
        onClick={onClick}
        className={classes.join(" ")}
        style={{ ...style, alignItems, justifyContent }}
      >
        {children}
      </div>
    );
  },
);

export default Row;
