import * as React from "react";
import useOnScreen from "../awareness/useOnScreen";
import {
  ImageSlideContainer,
  ImageTarget,
} from "../imageSlideContainer/ImageSlideContainer";
import Col from "../layout/Col";
import Page from "../layout/Page";
import Row from "../layout/Row";
import about_arcane_0 from "./about_arcane_0.jpg";
import about_arcane_1 from "./about_arcane_1.jpg";
import "./ArcanePage.scss";

const images = [about_arcane_0, about_arcane_1];
const targets: ImageTarget[] = [
  { left: 0, top: 0 },
  { left: 0, top: 0 },
];

type Props = {};

const ArcanePage = React.forwardRef<HTMLDivElement, Props>((_, ref) => {
  const imageSliderRef = React.useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(imageSliderRef);
  return (
    <Page background="#fff" className="page-arcane">
      <Row justifyContent="center" style={{ marginBottom: "4rem" }}>
        <Col className="slide-container-col" xs={12}>
          <ImageSlideContainer
            ref={imageSliderRef}
            background="#191915"
            images={images}
            targets={targets}
            pageControls={false}
          >
            <h2 className={isOnScreen ? "active" : ""}>Built from scratch</h2>
          </ImageSlideContainer>
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col ref={ref} xs={12} sm={10} md={8}>
          <h3>About Arcane</h3>
          <p>
            Normally, a rally car is just a modified production car. It has its
            benefits, but the things you don’t like or work well aren’t easy to
            adjust. That’s why we took all those lessons learned and start
            building. From scratch. Our car is fuelled by passion, dreams,
            technical expertise and lots of hours in the dirt, on the tracks.
            Whenever we came upon a part that just didn’t cut it, we designed
            and manufactured it ourselves. With every turn we took, we were
            thinking ahead. Just like a rally. With every presumable wall we
            hit, we worked around it and made it better. No compromises, only
            aiming for the very best.
          </p>
        </Col>
      </Row>
    </Page>
  );
});
export default ArcanePage;
