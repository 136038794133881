import * as React from "react";

export const useIsHovered = (ref: React.RefObject<HTMLElement>) => {
  const [isHovered, setIsHovered] = React.useState(false);

  React.useEffect(() => {
    const onMouseOver = () => {
      setIsHovered(true);
    };
    const onMouseOut = () => {
      setIsHovered(false);
    };
    const el = ref.current;
    el?.addEventListener("mouseover", onMouseOver);
    el?.addEventListener("mouseout", onMouseOut);
    return () => {
      el?.removeEventListener("mouseover", onMouseOver);
      el?.removeEventListener("mouseout", onMouseOut);
    };
  }, [ref]);
  return isHovered;
};
