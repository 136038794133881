import axios from "axios";
import * as React from "react";
import useAlternates from "../awareness/useAlternates";
import Col from "../layout/Col";
import Page from "../layout/Page";
import Row from "../layout/Row";
import ResizableIframe from "../news/ResizableIframe";
import "./AdminPage.scss";
import "../news/NewsPage.scss";
import fb_dots from "./fb-dots.jpg";
import fb_enclose from "./fb-insluiten.jpg";
import fb_copy from "./fb-copy.jpg";
import ResizableTextarea from "./ResizableTextArea";

type KeyedPost = {
  value: string;
  key: string;
  order: number;
};

type Post = {
  src: string;
  width: number;
  height: number;
  order: number;
};

let globalKeyIndex: number = 0;
const deepClone = (posts: KeyedPost[]): KeyedPost[] =>
  posts.map(({ value, key, order }) => ({ value, key, order }));

const AdminPage = () => {
  const pageRef = React.useRef<HTMLDivElement>(null);
  const panelRef = React.useRef<HTMLDivElement>(null);
  const [alternateTop, alternateHeight] = useAlternates(panelRef, pageRef);
  const [keyedPosts, setKeyedPosts] = React.useState<KeyedPost[]>([]);
  const [posts, setPosts] = React.useState<Post[]>([]);
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth,
  );
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  React.useEffect(() => {
    const loadPost = async () => {
      globalKeyIndex = 0;
      const result = await axios.get(`/facebook.txt?d=${new Date().getTime()}`);
      setKeyedPosts(
        result.data
          .split("\n")
          .filter((str: string): boolean => str.trim().length > 0)
          .map(
            (value: string, i): KeyedPost => ({
              value,
              order: i,
              key: `post-${globalKeyIndex++}`,
            }),
          ),
      );
    };
    loadPost();
  }, []);

  const savePosts = React.useCallback(async () => {
    const data = new FormData();
    data.append("username", email);
    data.append("password", password);
    const sorted = [...keyedPosts];
    sorted.sort((a, b) => a.order - b.order);
    data.append(
      "posts",
      sorted
        .filter((p) => p.value.trim().length !== 0)
        .map((p) => p.value)
        .join(","),
    );
    const result = await axios.post(
      "https://www.arcane-racing.nl/upload_news.php",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );
    alert(result.data);
  }, [email, password, keyedPosts]);

  React.useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  React.useEffect(() => {
    const sorted = keyedPosts.filter(
      (p) => p !== null && p!.value.trim().length > 0,
    );
    sorted.sort((a, b) => a.order - b.order);
    const newPosts: Post[] = sorted
      .map((p) => ({ str: p!.value, order: p!.order }))
      .map(({ str, order }) => {
        const src = /src="([^"]+)"/gm.exec(str)?.[1] || "";
        const width = parseInt(/width="([\d]+)"/gm.exec(str)?.[1] || "0");
        const height = parseInt(/height="([\d]+)"/gm.exec(str)?.[1] || "0");
        if (src !== "" && width !== 0 && height !== 0) {
          return { src, width, height, order };
        }
        return null;
      })
      .filter((res: Post | null) => res !== null) as Post[];
    setPosts(newPosts);
  }, [keyedPosts]);

  const numColumns = React.useMemo(
    () =>
      Math.max(
        Math.floor(
          (windowWidth - 150 - (panelRef.current?.clientWidth || 0)) / 500,
        ),
        1,
      ),
    [windowWidth, panelRef.current?.clientWidth],
  );

  const columns = React.useMemo(() => {
    if (posts.length === 0) {
      return undefined;
    }
    return Array.from({ length: numColumns }, (_, col) => {
      return (
        <Col
          key={`col-${col}`}
          className="masonry-col"
          style={{ maxWidth: "100%" }}
        >
          {posts
            .filter((_, i) => i % numColumns === col)
            .map(({ src, width, height, order }, i) => {
              return (
                <div key={src}>
                  <h2>{order}</h2>
                  <ResizableIframe
                    title={`News ${i}`}
                    src={src}
                    windowWidth={windowWidth}
                    width={width}
                    height={height}
                  />
                </div>
              );
            })}
        </Col>
      );
    });
  }, [numColumns, posts, windowWidth]);

  return (
    <Page
      ref={pageRef}
      className="admin-page"
      background="#fff"
      alternate="#92A1A2"
      alternateTop={alternateTop}
      alternateHeight={alternateHeight}
    >
      <Row
        className="admin-panel-wrapper"
        style={{
          backgroundColor: "#92A1A2",
        }}
      >
        <Col
          ref={panelRef}
          className="admin-panel"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
          }}
        >
          <h2>Beheer nieuws</h2>
          <p style={{ margin: "0 0 1rem 0" }}>
            Vul onderstaand de login gegevens in.
          </p>
          <div className="credential">
            <label htmlFor="email">email</label>
            <input
              name="email"
              type="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </div>
          <div className="credential">
            <label htmlFor="password">password</label>
            <input
              name="password"
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              margin: "1rem 0",
              border: "1px dashed black",
            }}
          />
          <p style={{ margin: "0 0 2rem 0" }}>
            <b>Stap 1.</b> Ga naar{" "}
            <a
              href="https://www.facebook.com/arcaneracing/?ref=pages_you_manage"
              target="_blank"
              rel="noreferrer"
            >
              facebook
            </a>{" "}
            en zoek de post op die je wil <b>insluiten</b>. <br />
            <b>Stap 2.</b> Klik in de post op{" "}
            <img
              alt="FB-Dots"
              style={{ height: 24, verticalAlign: "bottom" }}
              src={fb_dots}
            />{" "}
            en kies in de popup voor{" "}
            <img
              alt="FB-Enclose"
              style={{ height: 24, verticalAlign: "bottom" }}
              src={fb_enclose}
            />
            .<br />
            <b>stap 3.</b> Klik op{" "}
            <img
              alt="FB-Copy"
              style={{ height: 24, verticalAlign: "bottom" }}
              src={fb_copy}
            />
            .<br />
            <b>stap 4.</b> Klik hieronder op <em>Nieuwe Post toevoegen</em> en
            plak de gekopiëerde post in het nieuwe tekstveld.
            <br />
            <b>stap 5.</b> Controleer de post aan de rechterkant en klik onderin
            op <em>Opslaan</em>
            <br />
            &nbsp;
            <br />
            Je kunt de volgorde wijzigen door de pijltjes tussen de posts te
            gebruiken.
            <br /> Posts kunnen verwijderd worden door op het prullenbakje te
            klikken.
          </p>
          <button
            style={{
              justifyContent: "flex-end",
              width: "100%",
              height: "2rem",
            }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              const newPost: KeyedPost = {
                value: "",
                order: 0,
                key: `post-${globalKeyIndex++}`,
              };
              const sorted = [...keyedPosts];
              sorted.sort((a, b) => a.order - b.order);
              setKeyedPosts(
                [newPost].concat(
                  sorted.map((p, i) => ({ ...p, order: i + 1 })),
                ),
              );
            }}
          >
            <h2 style={{ marginRight: "1rem" }}>Nieuwe Post toevoegen</h2>
            <svg
              style={{
                height: "100%",
              }}
              aria-hidden="true"
              focusable="false"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"></path>
            </svg>
          </button>
          <form>
            {keyedPosts.map(({ value, key, order }, i) => (
              <div key={key} className="form-post" style={{ order }}>
                <button
                  disabled={order === 0}
                  className="arrow"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    const clones = deepClone(keyedPosts);
                    const current = clones[i];
                    const previous = clones.find(
                      (c) => c.order === current.order - 1,
                    );
                    if (previous) {
                      current.order--;
                      previous.order++;
                    }
                    setKeyedPosts(clones);
                  }}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path>
                  </svg>
                </button>
                <div className="post">
                  <h2>{keyedPosts[i].order}</h2>
                  <ResizableTextarea
                    value={value}
                    onChange={(e) => {
                      const newPosts = [...keyedPosts];
                      newPosts[i] = { ...newPosts[i], value: e.target.value };
                      setKeyedPosts(newPosts);
                    }}
                  />
                  <button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="button"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Weet je zeker dat je dit nieuwsbericht wil verwijderen?",
                          )
                        ) {
                          const newPosts = [...keyedPosts];
                          newPosts.splice(i, 1);
                          newPosts.forEach(
                            (post, index) => (post.order = index),
                          );
                          setKeyedPosts(newPosts);
                        }
                      }}
                    >
                      <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                    </svg>
                  </button>
                </div>
                <button
                  disabled={order === keyedPosts.length - 1}
                  className="arrow"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    const clones = deepClone(keyedPosts);
                    const current = clones[i];
                    const next = clones.find(
                      (c) => c.order === current.order + 1,
                    );
                    if (next) {
                      current.order++;
                      next.order--;
                    }
                    setKeyedPosts(clones);
                  }}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path>
                  </svg>
                </button>
              </div>
            ))}
          </form>
          <button
            style={{ margin: "1rem auto" }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              savePosts();
            }}
          >
            Opslaan
          </button>
        </Col>
        <Col>
          <h2>Voorbeeld</h2>
          <Row>{columns}</Row>
        </Col>
      </Row>
    </Page>
  );
};

export default AdminPage;
