import * as React from "react";
import Page from "../layout/Page";
import Row from "../layout/Row";
import slider_0 from "../coming_soon/slider_0.jpg";
import slider_1 from "../coming_soon/slider_1.jpg";
import slider_2 from "../coming_soon/slider_2.jpg";
import Col from "../layout/Col";
import AboutDetailImage from "./AboutDetailImage";
import aboutDetail0 from "./detail_0.jpg";
import aboutDetail1 from "./detail_1.jpg";
import aboutDetail2 from "./detail_2.jpg";
import aboutDetail3 from "./detail_3.jpg";
import "./AboutPage.scss";
import Persevere from "./Persevere";
import ImageBox from "../imagebox/ImageBox";
import useAlternates from "../awareness/useAlternates";

const images = [slider_0, slider_1, slider_2];

type Props = {};

const AboutPage = React.forwardRef<HTMLDivElement, Props>((_, ref) => {
  const pageRef = React.useRef<HTMLDivElement>(null);
  const altRef = React.useRef<HTMLDivElement>(null);
  const [alternateTop, alternateHeight] = useAlternates(altRef, pageRef);
  return (
    <Page
      ref={pageRef}
      background="#fff"
      alternate="#7C817D"
      className="about-page"
      alternateTop={alternateTop}
      alternateHeight={alternateHeight}
    >
      <Row justifyContent="center">
        <Col xs={12} alignItems="center">
          <Persevere />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col xs={12} sm={10}>
          <ImageBox images={images} />
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col
          ref={ref}
          className="about-car"
          xs={12}
          sm={10}
          md={8}
          alignItems="flex-start"
        >
          <h3>About the car</h3>
          <p>
            It’s time to get familiar. The Arcane rally car has a reliable
            Yamaha 1000cc turbo motor with 240hp. It’s light as feather (900kg),
            spacious and Reiger suspended. The Arcane has a closed cockpit, has
            4x4 drive and a manual (sequential) transmission. Fun driving,
            anyone? Because of the design you have optimal vision, great
            lighting and… to be fair: looks amazing. Built by the latest FIA
            norms (FIA/Dakar passport is not a problem) you can use the Arcane
            rally car in any T3 rally race. It is built in buggy dimensions but
            trust us, it drives like a rally car. Everything about the car is
            top-notch. The suspension is equal on all sides and every side has
            the same components so… less spares! To top it off, it’s built to
            last. To persevere. Just like you.
          </p>
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col xs={12} sm={9} md={6} alignItems="center">
          <h3 style={{ textAlign: "center" }}>
            If you want to know more about Arcane or the car, please let us
            know.
          </h3>
          <a
            href="mailto:info@arcane-racing.com"
            target="_blank"
            className="tell-me-more"
            rel="noreferrer"
          >
            tell me more
          </a>
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col xs={12} sm={10}>
          <h3>About the details</h3>
        </Col>
      </Row>
      <Row justifyContent="center">
        <AboutDetailImage ref={altRef} src={aboutDetail0} first>
          <h2>Fully equipped cockpit</h2>
          <p>
            It’s the perfect working space. It is very spacious (for a rally
            car) and has everything within an arms length.
          </p>
        </AboutDetailImage>
        <AboutDetailImage src={aboutDetail1}>
          <h2>Comfortable driving</h2>
          <p>
            Being lightweight, you still have the comfort of a closed cockpit.
            The weight distribution (front and back) is a perfect 50/50 and the
            car has Military Spec Wiring. It feels like you’re driving an actual
            car.
          </p>
        </AboutDetailImage>
      </Row>
      <Row justifyContent="center">
        <AboutDetailImage src={aboutDetail2} first>
          <h2>No compromises</h2>
          <p>
            The car has a Kevlar body, all electronics are designed in one unit
            and all units are connected. Steering is optimal and the Reigers are
            equipped with corner control. We didn’t cut any corners, so that our
            car can.
          </p>
        </AboutDetailImage>
        <AboutDetailImage src={aboutDetail3}>
          <h2>Less spares</h2>
          <p>
            The suspension is equal on all sides and every side has the same
            components. Examples: wheel hubs, drive shafts, shock absorbers,
            a-arms. So…when everything turns south…you’re good to go in no time.
          </p>
        </AboutDetailImage>
      </Row>
    </Page>
  );
});

export default AboutPage;
