import * as React from "react";

const backupRect = new DOMRect(0, 0, 1, 1);
const useResize = (ref: React.RefObject<HTMLElement>): DOMRect => {
  const [rect, setRect] = React.useState<DOMRect>(
    ref.current?.getBoundingClientRect() || backupRect,
  );
  React.useEffect(() => {
    const onResize = () => {
      setRect(ref.current?.getBoundingClientRect() || backupRect);
    };
    const resizeObserver = new ResizeObserver(onResize);
    if (ref.current) {
      onResize();
      resizeObserver.observe(ref.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, setRect]);
  return rect;
};

export default useResize;
