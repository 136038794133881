import * as React from "react";

const useAlternates = (
  altRef: React.RefObject<HTMLElement> | undefined,
  pageRef: React.RefObject<HTMLDivElement>,
): [alternateTop: string | undefined, alternateHeight: string | undefined] => {
  const [alternateTop, setAlternateTop] = React.useState<string | undefined>();
  const [alternateHeight, setAlternateHeight] = React.useState<
    string | undefined
  >();
  React.useEffect(() => {
    const applyAlternateHeight = () => {
      if (altRef?.current && pageRef.current) {
        const imageHeight = altRef.current.clientHeight;
        const imageTop = altRef.current.offsetTop;
        const pageHeight = pageRef.current.getBoundingClientRect().height;
        const altHeight = imageTop + 0.5 * imageHeight;
        setAlternateTop(`${altHeight}px`);
        setAlternateHeight(`${pageHeight - altHeight}px`);
      }
    };
    applyAlternateHeight();

    const observer = new ResizeObserver(applyAlternateHeight);
    if (pageRef.current) {
      observer.observe(pageRef.current);
    }
    window.addEventListener("resize", applyAlternateHeight);
    return () => {
      window.removeEventListener("resize", applyAlternateHeight);
      observer.disconnect();
    };
  }, [altRef, pageRef, setAlternateTop, setAlternateHeight]);
  return [alternateTop, alternateHeight];
};

export default useAlternates;
