import * as React from "react";
import Col from "../layout/Col";
import Row from "../layout/Row";
import logo from "../logo.png";
import "../layout/Header.scss";

const Header = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [normal, setNormal] = React.useState<number>(0);
  React.useEffect(() => {
    const onScroll = () => {
      if (headerRef.current) {
        const normal = Math.max(
          Math.min(
            document.documentElement.scrollTop /
              headerRef.current?.clientHeight,
            1,
          ),
          0,
        );
        setNormal(normal);
      }
    };
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [headerRef]);
  const shadowDist = `${normal * 10}px`;
  const shadowOpacity = 0.1 * normal;
  return (
    <div
      className="header"
      ref={headerRef}
      style={{
        boxShadow: `rgba(33, 35, 38, ${shadowOpacity}) 0px ${shadowDist} ${shadowDist} -${shadowDist}`,
      }}
    >
      <Row alignItems="center">
        <Col xs={8}>
          <img src={logo} width={113} height={12} alt="Arcane Logo" />
        </Col>
      </Row>
    </div>
  );
};

export default Header;
