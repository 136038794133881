import * as React from "react";
import useOnScreen from "../awareness/useOnScreen";
import useResize from "../awareness/useResize";
import "./Counter.scss";

type Props = {
  count: number;
  delay?: number;
};

const Counter = ({ count, delay = 0 }: Props) => {
  const counterRef = React.useRef<HTMLDivElement>(null);
  const spanRef = React.useRef<HTMLSpanElement>(null);
  const active = useOnScreen(counterRef);
  const { height } = useResize(spanRef);
  const getStyle = React.useCallback(
    (c: number, index: number): React.CSSProperties => {
      if (active) {
        return {
          transform: `translateY(-${height * c}px)`,
          transition: `transform ${Math.min(count * 5, 1000)}ms`,
          transitionDelay: `${delay}ms`,
        };
      }
      return {};
    },
    [count, height, active, delay],
  );
  const parts = count
    .toString()
    .split("")
    .map((s) => parseInt(s));

  let currentCount = 0;
  return (
    <div ref={counterRef} className="counter" style={{ height }}>
      {parts.map((c, i) => {
        const value = (
          <div
            key={`number-box-${i}`}
            className="number-box"
            style={getStyle(currentCount + c, i)}
          >
            {Array.from({ length: currentCount + 10 }, (_, j) => (
              <span
                ref={i === 0 && j === 0 ? spanRef : undefined}
                key={`number-${j}`}
              >
                {j % 10}
              </span>
            ))}
          </div>
        );
        currentCount += 10 * c;
        return value;
      })}
    </div>
  );
};

export default Counter;
