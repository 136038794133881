import * as React from "react";
import "./ImageBox.scss";

type Props = {
  images: string[];
};

const ImageBox = ({ images }: Props) => {
  React.useEffect(() => {
    images.forEach((image) => (new Image().src = image));
  }, [images]);
  const removedRef = React.useRef(false);
  const timeOutRef = React.useRef<NodeJS.Timeout>();
  const imageWrapperRef = React.useRef<HTMLDivElement>(null);
  const imagesRef = React.useRef<HTMLDivElement>(null);
  const [page, setPage] = React.useState(0);
  const offset = React.useMemo(() => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
    }
    if (removedRef.current === false) {
      timeOutRef.current = setTimeout(() => {
        timeOutRef.current = undefined;
        if (removedRef.current === false) {
          setPage((page + 1) % images.length);
        }
      }, 8000);
    }
    return page * (imageWrapperRef.current?.clientWidth || 0);
  }, [page, setPage, images]);

  React.useEffect(() => {
    return () => {
      removedRef.current = true;
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, []);

  return (
    <div className="image-box">
      <div
        className="image-wrapper"
        style={{
          height: (imageWrapperRef.current?.clientWidth || 500) * (9 / 16),
        }}
        ref={imageWrapperRef}
      >
        <div
          className="images"
          style={{ transform: `translateX(-${offset}px)` }}
          ref={imagesRef}
        >
          {Array.from({ length: images.length }, (_, i) => {
            return (
              <div
                style={{
                  width: imageWrapperRef.current?.clientWidth,
                  height: imageWrapperRef.current?.clientHeight,
                }}
                className="image-slot"
                key={`image-${i}`}
              >
                <img src={images[i]} alt={`Arcane-${page}`} />
              </div>
            );
          })}
        </div>
        <div
          className="arrow-left"
          role="button"
          onClick={() => {
            let prevPage = page - 1;
            if (prevPage < 0) {
              prevPage = images.length - 1;
            }
            setPage(prevPage);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.11"
            height="20.712"
            viewBox="0 0 14.11 20.712"
          >
            <path
              d="M3725.857,1648.414l-11.2,9.34,11.2,9.34"
              transform="translate(-3712.595 -1647.398)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.647"
            />
          </svg>
        </div>
        <div
          className="arrow-right"
          role="button"
          onClick={() => setPage((page + 1) % images.length)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.11"
            height="20.712"
            viewBox="0 0 14.11 20.712"
          >
            <path
              d="M3725.857,1648.414l-11.2,9.34,11.2,9.34"
              transform="translate(-3712.595 -1647.398)"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2.647"
            />
          </svg>
        </div>
      </div>
      <div className="pager">
        {Array.from({ length: images.length }, (_, i) => {
          return (
            <div
              key={`pager-button-${i}`}
              role="button"
              onClick={() => setPage(i)}
              className={`pager-item ${i === page ? "active" : ""}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageBox;
