import * as React from "react";
import persevere_0 from "./persevere_0.jpg";
import persevere_1 from "./persevere_1.jpg";
import persevere_2 from "./persevere_2.jpg";
import Counter from "../counter/Counter";
import "./Persevere.scss";
import useOnScreen from "../awareness/useOnScreen";
import {
  ImageSlideContainer,
  ImageTarget,
} from "../imageSlideContainer/ImageSlideContainer";
import Col from "../layout/Col";
import Row from "../layout/Row";

const images = [persevere_0, persevere_1, persevere_2];
const targets: ImageTarget[] = [
  { left: 50, top: 50 },
  { left: 50, top: 50 },
  { left: 50, top: 50 },
];

const Persevere = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ref);
  return (
    <Row className="persevere">
      <Col className="slide-container-col" xs={12}>
        <ImageSlideContainer
          ref={ref}
          background="#191915"
          images={images}
          targets={targets}
          pageControls={false}
        >
          <h2 className={isOnScreen ? "active" : ""}>Let's persevere</h2>
          <div className="persevere-text-container">
            <div className={`persevere-text ${isOnScreen ? "active" : ""}`}>
              <div className="persevere-text-row large">
                <Counter count={240} delay={300} />
                hp
              </div>
              <div className="persevere-text-row small">power engine</div>
            </div>
            <div className={`persevere-text ${isOnScreen ? "active" : ""}`}>
              <div className="persevere-text-row large">
                <Counter count={900} delay={600} />
                kg
              </div>
              <div className="persevere-text-row small">weight</div>
            </div>
            <div className={`persevere-text ${isOnScreen ? "active" : ""}`}>
              <div className="persevere-text-row large">
                <Counter count={1000} delay={900} />
                cc
              </div>
              <div className="persevere-text-row small">turbo motor</div>
            </div>
          </div>
        </ImageSlideContainer>
      </Col>
    </Row>
  );
};

export default Persevere;
/*
<div ref={ref} className="persevere">
      <ImageHeader ref={imageHeaderRef} images={images} />
      
    </div>
    */
