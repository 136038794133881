import * as React from "react";
import Col from "../layout/Col";
import Page from "../layout/Page";
import Row from "../layout/Row";
import useAlternates from "../awareness/useAlternates";
import bumper_0 from "./bumper_0.jpg";
import bumper_1 from "./bumper_1.jpg";
import bumper_2 from "./bumper_2.jpg";
import bumper_3 from "./bumper_3.jpg";
import "./BumperPage.scss";
import {
  ImageSlideContainer,
  ImageTarget,
} from "../imageSlideContainer/ImageSlideContainer";
import useOnScreen from "../awareness/useOnScreen";
const images = [bumper_0, bumper_1, bumper_2, bumper_3];
const targets: ImageTarget[] = [
  { left: 25, top: 44 },
  { left: 40, top: 30 },
  { left: 55, top: 50 },
  { left: 55, top: 50 },
];
const BumperPage = () => {
  const imageSliderRef = React.useRef<HTMLDivElement>(null);
  const imageSliderOnScreen = useOnScreen(imageSliderRef);
  const pageRef = React.useRef<HTMLDivElement>(null);
  const [alternateTop, alternateHeight] = useAlternates(
    imageSliderRef,
    pageRef,
  );

  return (
    <Page
      ref={pageRef}
      className="bumper-page"
      background="#fff"
      alternate="#191915"
      alternateTop={alternateTop}
      alternateHeight={alternateHeight}
    >
      <Row>
        <Col className="slide-container-col" xs={12}>
          <ImageSlideContainer
            ref={imageSliderRef}
            background="#191915"
            images={images}
            targets={targets}
            downArrow
          >
            <h2 className={imageSliderOnScreen ? "active" : ""}>
              WHEN YOU GO ON A WILD,
              <br />
              LIFE CHANGING JOURNEY..
            </h2>
            <p className={imageSliderOnScreen ? "active" : ""}>arcane</p>
          </ImageSlideContainer>
        </Col>
      </Row>
      <Row justifyContent="center">
        <Col xs={12} sm={10} md={8}>
          <p>
            When you go on a wild, life changing, demanding and exhilarating
            journey you come out a changed man. Either you do it again (but
            better) or you just let it be. Inspired by the Dakar rally, we
            wanted to do it again. But better. To do so, we’ve changed the game.
            Normally, a rally car is based on a production car to be modified
            for racing. But what if…we build one <em>from scratch</em>. Take all
            the lessons we’ve learned and create something completely new.
            Something that’s built to last. To endure. To win. And that’s just
            what we did. Arcane <em>~ let’s persevere</em>
          </p>
        </Col>
      </Row>
      <Row justifyContent="center" style={{ marginBottom: "8em" }}>
        <a
          href="mailto:info@arcane-racing.com"
          target="_blank"
          className="receive-updates"
          rel="noreferrer"
        >
          receive updates
        </a>
      </Row>
    </Page>
  );
};

export default BumperPage;
