import * as React from "react";

const useOnScreen = (
  ref: React.RefObject<HTMLElement> | undefined,
): boolean => {
  const [isIntersecting, setIntersecting] = React.useState(false);
  React.useEffect(() => {
    if (!ref?.current) {
      return () => {};
    }
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};

export default useOnScreen;
