import * as React from "react";
import Col from "./Col";
import Row from "./Row";
import logo from "../logo.png";
import "./Header.scss";

export type HeaderTarget = {
  name: string;
  size: number;
  url?: string;
  ref?: React.RefObject<HTMLElement>;
};

type Props = {
  targets: HeaderTarget[];
};

const Header = ({ targets }: Props) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [normal, setNormal] = React.useState<number>(0);
  React.useEffect(() => {
    const onScroll = () => {
      if (headerRef.current) {
        const normal = Math.max(
          Math.min(
            document.documentElement.scrollTop /
              headerRef.current?.clientHeight,
            1,
          ),
          0,
        );
        setNormal(normal);
      }
    };
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [headerRef]);
  const shadowDist = `${normal * 10}px`;
  const shadowOpacity = 0.1 * normal;
  return (
    <div
      className="header"
      ref={headerRef}
      style={{
        boxShadow: `rgba(33, 35, 38, ${shadowOpacity}) 0px ${shadowDist} ${shadowDist} -${shadowDist}`,
      }}
    >
      <Row alignItems="center">
        <Col xs={12} sm={7} md={8}>
          <img src={logo} width={113} height={12} alt="Arcane Logo" />
        </Col>
        <Col xs={12} sm={5} md={4}>
          <Row
            style={{ width: "100%" }}
            alignItems="center"
            justifyContent="space-between"
          >
            {targets.map((target, i) => (
              <div
                key={`col-${i}`}
                className="header-button"
                onClick={() => {
                  const rect = target.ref?.current?.getBoundingClientRect();
                  if (rect !== undefined) {
                    window.scrollTo({
                      behavior: "smooth",
                      top:
                        rect.top -
                        0.5 * window.innerHeight +
                        0.5 * rect.height +
                        window.scrollY,
                    });
                  } else if (target.url !== undefined) {
                    window.open(target.url!, "_blank");
                  }
                }}
              >
                {target.name}
              </div>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
