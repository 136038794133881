import * as React from "react";
import styled from "styled-components";
import useOnScreen from "../awareness/useOnScreen";
import useResize from "../awareness/useResize";
import "./ImageSlideContainer.scss";

export type ImageTarget = {
  left: number;
  top: number;
  scale?: number;
};

type Props = {
  background: string;
  images: string[];
  targets: ImageTarget[];
  pageControls?: boolean;
  downArrow?: boolean;
};

export type ImageSlideContainerRef = {
  rect: DOMRect;
  ref: React.RefObject<HTMLDivElement>;
  nextPage: () => void;
};

export const ImageSlideContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(
  (
    {
      background,
      children,
      images,
      targets,
      pageControls = true,
      downArrow = false,
    },
    containerRef,
  ) => {
    const timeoutRef = React.useRef<NodeJS.Timeout>();
    const [page, setPage] = React.useState<number>(-1);
    const isOnScreen = useOnScreen(
      containerRef as React.RefObject<HTMLDivElement>,
    );
    const rect = useResize(containerRef as React.RefObject<HTMLDivElement>);

    const nextPage = React.useCallback(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setPage((page + 1) % images.length);
    }, [page, images.length]);

    React.useEffect(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(nextPage, 30000);
    }, [nextPage]);

    React.useEffect(() => {
      if (!isOnScreen && timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      } else if (isOnScreen) {
        timeoutRef.current = setTimeout(nextPage, 30000);
      }
    }, [isOnScreen, nextPage]);

    React.useEffect(() => {
      nextPage();
    }, []);

    const imageComponents = React.useMemo(
      () =>
        images.map((image, i) => (
          <SliderImage
            key={`image-${i}`}
            active={page === i && isOnScreen}
            image={image}
            target={targets[i]}
            containerRect={rect}
          />
        )),
      [page, isOnScreen, images, rect, targets],
    );

    const pager = React.useMemo(
      () => (
        <div className="pager">
          {Array.from({ length: images.length }, (_, i) => {
            return (
              <div
                key={`pager-button-${i}`}
                role="button"
                onClick={() => setPage(i)}
                className={`pager-item ${i === page ? "active" : ""}`}
              />
            );
          })}
        </div>
      ),
      [page, images.length],
    );

    return (
      <div
        ref={containerRef}
        className="image-slide-container"
        style={{ backgroundColor: background }}
      >
        {imageComponents}
        <div className="overlay">
          {children}
          {downArrow && (
            <div
              className={`arrow-down ${isOnScreen ? "active" : ""}`}
              role="button"
              onClick={nextPage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.11"
                height="20.712"
                viewBox="0 0 14.11 20.712"
              >
                <path
                  d="M3725.857,1648.414l-11.2,9.34,11.2,9.34"
                  transform="translate(-3712.595 -1647.398)"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeWidth="2.647"
                />
              </svg>
            </div>
          )}
        </div>
        {pageControls && pager}
      </div>
    );
  },
);

const StyledImg = styled.img<{ left: string; top: string; scale: number }>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  transform: scale(${({ scale }) => scale});
  &.active {
    transform: scale(${({ scale }) => scale + 0.25});
  }
`;

type SliderImageProps = {
  active: boolean;
  image: string;
  target: ImageTarget;
  containerRect: DOMRect;
};

const SliderImage = ({
  active,
  image,
  target,
  containerRect,
}: SliderImageProps) => {
  const ref = React.useRef<HTMLImageElement>(null);
  const rect = useResize(ref);

  const offsetX = Math.min(
    Math.max(
      0.5 * containerRect.width - target.left * 0.01 * rect.width,
      containerRect.width - rect.width,
    ),
    0,
  );

  const offsetY = Math.min(
    Math.max(
      0.5 * containerRect.height - target.top * 0.01 * rect.height,
      containerRect.height - rect.height,
    ),
    0,
  );

  const classNames = ["image"];
  if (active) {
    classNames.push("active");
  }

  return (
    <StyledImg
      ref={ref}
      left={`${offsetX}px`}
      top={`${offsetY}px`}
      scale={target.scale || 1}
      className={classNames.join(" ")}
      src={image}
      alt="Slider"
    />
  );
};
