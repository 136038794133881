import * as React from "react";

type Props = {
  title: string;
  src: string;
  width: number;
  height: number;
  windowWidth: number;
};

const ResizableIframe = ({ title, src, width, height, windowWidth }: Props) => {
  const scale = Math.min(windowWidth, 500) / width;
  return (
    <div
      key={src}
      className="fb-container"
      style={{ width: width * scale, height: height * scale }}
    >
      <iframe
        key={src}
        title={title}
        src={`${src}&d=${new Date().getTime()}`}
        frameBorder="0"
        scrolling="no"
        style={{
          border: 0,
          height: height,
          width: width,
          transform: `scale(${scale})`,
          transformOrigin: "0 0",
        }}
      />
    </div>
  );
};

export default ResizableIframe;
