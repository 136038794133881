import * as React from "react";
import Col from "../layout/Col";
import Page from "../layout/Page";
import Row from "../layout/Row";
import NewsLetter from "../news_letter/NewsLetter";
import "./NewsPage.scss";
import axios from "axios";
import ResizableIframe from "./ResizableIframe";

type Post = {
  src: string;
  width: number;
  height: number;
};

const NewsPage = () => {
  React.useEffect(() => {
    const loadPost = async () => {
      const result = await axios.get(`/facebook.txt?d=${new Date().getTime()}`);
      setPosts(
        result.data
          .split("\n")
          .filter((str: string) => str.trim().length > 0)
          .map((str: string) => {
            const src = /src="([^"]+)"/gm.exec(str)?.[1] || "";
            const width = parseInt(/width="([\d]+)"/gm.exec(str)?.[1] || "0");
            const height = parseInt(/height="([\d]+)"/gm.exec(str)?.[1] || "0");
            if (src !== "" && width !== 0 && height !== 0) {
              return { src, width, height };
            }
            return null;
          })
          .filter((res: Post | null) => res !== null),
      );
    };
    loadPost();
  }, []);

  const [posts, setPosts] = React.useState<Post[]>([]);
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth,
  );
  React.useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const numColumns = React.useMemo(
    () => Math.max(Math.floor(Math.min(windowWidth, 1600) / 500), 1),
    [windowWidth],
  );

  const columns = React.useMemo(() => {
    if (posts.length === 0) {
      return undefined;
    }
    return Array.from({ length: numColumns }, (_, col) => {
      return (
        <Col
          key={`col-${col}`}
          className="masonry-col"
          style={{ maxWidth: "100%" }}
        >
          {posts
            .filter((_, i) => i % numColumns === col)
            .map(({ src, width, height }, i) => {
              return (
                <ResizableIframe
                  key={src}
                  title={`News ${i}`}
                  src={src}
                  windowWidth={windowWidth}
                  width={width}
                  height={height}
                />
              );
            })}
        </Col>
      );
    });
  }, [numColumns, posts, windowWidth]);

  return (
    <Page className="news-page" background="#92A1A2">
      <Row justifyContent="center">
        <Col xs={12} sm={10}>
          <h3>News</h3>
        </Col>
      </Row>
      <Row
        style={{ marginBottom: "6rem", maxWidth: 1600 }}
        justifyContent="center"
      >
        {columns}
      </Row>
      <NewsLetter />
    </Page>
  );
};

export default NewsPage;
