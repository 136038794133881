import * as React from "react";

type Props = {
  value: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

const ResizableTextarea = ({ value, onChange, placeholder }: Props) => {
  const ref = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.height = "0px";
      const scrollHeight = Math.max(ref.current.scrollHeight + 4, 24);
      ref.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <textarea
      ref={ref}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default ResizableTextarea;
