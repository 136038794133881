import * as React from "react";
import { FlexAlignItems, FlexJustifyContent } from ".";
import "./flexboxgrid.scss";

type Props = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  alignItems?: FlexAlignItems;
  justifyContent?: FlexJustifyContent;
  reverse?: boolean;
  className?: string;
  style?: React.CSSProperties;
};
const Col = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      xs,
      sm,
      md,
      lg,
      alignItems,
      justifyContent,
      reverse,
      className,
      style,
      children,
    },
    ref,
  ) => {
    const sizes = ["xs", "sm", "md", "lg"];
    const classes = ["col", reverse ? "reverse" : undefined]
      .concat(
        [xs, sm, md, lg].map((v, i) =>
          !!v ? `col-${sizes[i]}-${v}` : undefined,
        ),
      )
      .concat(className?.split(" "))
      .filter((v) => v !== undefined);

    return (
      <div
        ref={ref}
        className={classes.join(" ")}
        style={{ ...style, alignItems, justifyContent }}
      >
        {children}
      </div>
    );
  },
);

export default Col;
