import * as React from "react";
import styled from "styled-components";
import { useIsHovered } from "../awareness/useIsHovered";
import useResize from "../awareness/useResize";
import Col from "../layout/Col";

export type AboutDetailImageTarget = {
  left: number;
  top: number;
  scale?: number;
};

type Props = {
  src: string;
  target?: AboutDetailImageTarget;
  first?: boolean;
};

const StyledImg = styled.img<{ left: string; top: string; scale: number }>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  transform: scale(${({ scale }) => scale});
  &.active {
    transform: scale(${({ scale }) => scale + 0.25});
  }
`;

const AboutDetailImage = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(({ src, children, first = false, target = { top: 50, left: 50 } }, ref) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerRect = useResize(containerRef);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const rect = useResize(imageRef);

  const isHovered = useIsHovered(containerRef);

  const classes = ["about-detail", first ? "first" : undefined].filter(
    (v) => v !== undefined,
  );

  const scale = target.scale || 1;

  const offsetX = Math.min(
    Math.max(
      0.5 * containerRect.width - target.left * 0.01 * rect.width,
      containerRect.width - rect.width,
    ),
    0,
  );

  const offsetY = Math.min(
    Math.max(
      0.5 * containerRect.height - target.top * 0.01 * rect.height,
      containerRect.height - rect.height,
    ),
    0,
  );

  return (
    <Col
      xs={12}
      sm={5}
      className={classes.join(" ")}
      justifyContent="flex-start"
    >
      <div ref={ref}>
        <div
          ref={containerRef}
          className={`image-wrapper ${isHovered ? "active" : ""}`}
        >
          <StyledImg
            ref={imageRef}
            src={src}
            scale={scale}
            top={`${offsetY}px`}
            left={`${offsetX}px`}
            alt="About Detail"
          />
        </div>
      </div>
      {children}
    </Col>
  );
});

export default AboutDetailImage;
