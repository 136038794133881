import * as React from "react";
import "./Page.scss";

type Props = {
  background?: string;
  alternate?: string;
  alternateTop?: string;
  alternateHeight?: string;
  className?: string;
};

const Page = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      background,
      alternate,
      alternateTop = "50%",
      alternateHeight = "50%",
      className,
      children,
    }: React.PropsWithChildren<Props>,
    ref?: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={`page ${className}`}
        style={{ backgroundColor: background }}
      >
        <div
          className="page-alternate"
          style={{
            backgroundColor: alternate,
            top: alternateTop,
            height: alternateHeight,
          }}
        />
        <div style={{ position: "relative" }}>{children}</div>
      </div>
    );
  },
);

export default Page;
